
// ---------------------------------
// ||            Forms            ||
// ---------------------------------

form.custom-form {
	label {
		color: $form_label_fontcolor;
		font-size: $form_label_fontsize;
		font-weight: $form_label_fontweight;
		font-style: $form_label_fontstyle;
		margin-bottom: $form_label_marginbottom;
		padding-left: $form_label_paddingleft;
	}

	input:focus, textarea:focus {
		color: $form_input_fontcolor !important;
		background-color: $form_input_focus_bgcolor !important;
		border: none !important;
	}

	.form-check-label {
		font-size: $form_radiochecklabel_fontsize;
		font-weight: $form_radiochecklabel_fontweight;
		font-style: $form_radiochecklabel_fontstyle;
		margin-bottom: $form_radiochecklabel_marginbottom;
		padding-left: $form_radiochecklabel_paddingleft;
	}

	.form-control {
		padding: $form_input_padding;
		border: $form_input_border;
		border-radius: $form_input_borderradius;
		color: $form_input_fontcolor;
		background-color: $form_input_bgcolor;
		font-size: $form_input_fontsize;
		font-weight: $form_input_fontweight;
		font-style: $form_input_fontstyle;
		margin-bottom: $form_input_marginbottom;
	}
	.form-control.green { border: $form_input_border_green; }
	.form-control.red { border: $form_input_border_red; }

	.form-control::placeholder { color: $form_input_placeholder_color; }
	.form-control:-ms-input-placeholder { color: $form_input_placeholder_color; }
	.form-control::-ms-input-placeholder { color: $form_input_placeholder_color; }

	select.form-control, select.form-control:focus {
		color: $form_select_txtcolor;
		background-color: $form_select_bgcolor;
		* {
			color: $form_select_txtcolor;
			background-color: $form_select_bgcolor;
		}
	}

	.submit_container {
		text-align: $form_button_position;

		@if ($form_button_stylize == 1) {
			button {
				width: $form_button_width;
				padding: $form_button_padding;
				color: $form_button_color;
				background-color: $form_button_bgcolor;
				font-size: $form_button_fontsize;
				font-weight: $form_button_fontweight;
				border-radius: $form_button_borderradius;
			}
			button:hover {
				background-color: lighten($form_button_bgcolor, 10%);
			}
		}
	}

	.loader {
		pointer-events: none;
		position: absolute;
		z-index: 1000;
		width: $form_loader_size + $form_loader_padding;
		height: $form_loader_size + $form_loader_padding;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $form_loader_bgcolor;
		border-radius: 1000px;
	}
	.loader:after {
		content: " ";
		display: block;
		width: $form_loader_size;
		height: $form_loader_size;
		margin: $form_loader_padding * 0.5;
		border-radius: 50%;
		border: $form_loader_thickness solid $form_loader_color;
		border-color: $form_loader_color transparent $form_loader_color transparent;
		animation: loader $form_loader_speed linear infinite;
	}
	@keyframes loader {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
}