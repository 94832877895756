
$bleu: #6d8d92;
$jaune: #ae9627;
$jaune: rgba(168, 143, 38, 0.5);
$marron: #996633;
$marron: rgba(153, 102, 51, 0.2);
$noir: #000;
$blanc: #fff;

.test_ugp {
    * {
        font-family: 'Roboto', sans-serif !important;
    }
}

.animated_zone {
    //position: fixed;
    position: absolute;
    z-index: 10;
    overflow: hidden;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    //background-color: $bleu;
    //clip-path: circle(100% at 50% 50%);
    clip-path: inset(0 0 0 0);

    img {
        @extend %imgcover;
        z-index: -1;
        clip-path: circle(100% at 50% 50%);
    }

    .texte_debut {
        @extend %abcenter_xy;
        z-index: 15;
        text-align: center;
        color: white;
        white-space: nowrap;

        h1 {
            font-family: Arial;
            line-height: 1;
            
            .bold {
                font-weight: 700;
            }
            .thin {
                font-weight: 100;
            }
        }

        .soustitre {
            font-size: 1.4rem;
            font-weight: 700;
        }
    }

    .texte {
        @extend %abcenter_xy;
        z-index: 15;
        font-size: 25vw;
        font-weight: 100;
        color: white;
        opacity: 0;

        p {
            margin: 0;
            line-height: 1;
            text-align: center;
        }
        p:nth-child(1) { transform: translateX(-100px) }
        p:nth-child(2) { transform: translateX(100px) }
        p:nth-child(3) { transform: translateX(-150px) }
        p:nth-child(4) { transform: translateX(50px) }
        p:nth-child(5) { transform: translateX(-100px) }
    }

    .circles_container {
        @extend %abcenter_xy;
        z-index: 10;
        width: 180vw;
        height: 180vw;
        border-radius: 50%;
        transform: translate(-50%, -50%) rotate(-60deg);
        //background-color: rgba(red, 0.2);

        .circle {
            position: absolute;
            height: 200px;
            width: 200px;
            border-radius: 50%;
            background-color: $marron;
            backdrop-filter: blur(15px);

            &.yellow {
                //background-color: rgba($jaune, 0.5);
                z-index: 15;
                top: -50px;
                left: 40%;
            }
            &.black {
                //background-color: rgba($noir, 0.5);
                z-index: 13;
                left: 0%;
                bottom: 0%;
            }
            &.white {
                //background-color: rgba($blanc, 0.5);
                z-index: 14;
                right: 0%;
                bottom: 0%;
            }
            &.outerblack {
                z-index: 16;
                top: 50%;
                left: 50%;
                backdrop-filter: initial;
                background-color: transparent;
                border: 1px solid black;
                height: 0px;
                width: 0px;
                opacity: 1;
            }
        }
    }
}

.screenblock {
    display: block;
    width: 100%;
    height: 100vh;

    .half {
        position: absolute;
        top: 50%;
    }
    .double {
        position: absolute;
        top: 0;
        height: 200%;
    }

    // &._1 { background-color: #eee; }
    // &._2 { background-color: #ddd; }
    // &._3 { background-color: #ccc; }
    // &._4 { background-color: #bbb; }
}

.scroll_filler {
    margin-top: 200px;
    color: #eee;
}

.texte_remplissage {
    text-align: center;
    padding: 0 30px;

    h1 {
        font-family: Arial;
        line-height: 1;
        
        .bold {
            font-weight: 700;
        }
        .thin {
            font-weight: 100;
        }
    }

    .soustitre {
        font-size: 1.4rem;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .titre_thin {
        font-size: 16vw;
        font-weight: 100;
        line-height: 1;
        margin-bottom: 40px;
    }

    .soustitre_bold {
        font-size: 1.3rem;
        font-weight: 700;
        margin-bottom: 30px;
        line-height: 1.2;
    }

    .texte {
        text-align: left;
    }
}

.fond {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
}

.fond_ugp {
    background-image: url('../images/ugp/fond_test.png');
}

.animated_zone {
    top: 70vh;
}

.svg_container {
    .main_svg {
        width: 100%;
        
        path, ellipse {
            stroke-width: 0.5px;
            stroke: black;
            fill: none;
        }

        .cls- {
            stroke: red;
        }
    }

    .boule {
        width: 120px;
    }
}

.div_with_lag_1 {
    position: absolute;
    z-index: 1;
    top: 50vh;
    left: 100px;
    padding: 30px;
    background-color: green;
    font-size: 1.3rem;
    font-weight: 700;
}

.div_with_lag_2 {
    position: absolute;
    z-index: 1;
    top: 50vh;
    right: 100px;
    padding: 30px;
    background-color: lightblue;
    font-size: 1.3rem;
    font-weight: 700;
}

.div_with_parallax_1 {
    position: absolute;
    z-index: 1;
    top: 100vh;
    right: 100px;
    padding: 30px;
    background-color: green;
    font-size: 1.3rem;
    font-weight: 700;
}
.div_with_parallax_2 {
    position: absolute;
    z-index: 1;
    top: 100vh;
    left: 100px;
    padding: 30px;
    background-color: lightgreen;
    font-size: 1.3rem;
    font-weight: 700;
}

.test_carres {
    position: absolute;
    z-index: 10;
    top: 130vh;
    left: 5%;
    right: 5%;
    height: 120vh;

    .carre {
        position: absolute;
        height: 40px;
        width: 40px;
        border-radius: 6px;
    }
}

.flowerbutton_container {
    position: relative;
    width: 50%;
    margin: auto;

    button {
        position: absolute;
        background-color: green;
        top: 37.5%;
        left: 19%;
        width: 50%;
        height: 18%;
        border: none;
    }

    circle {
        transform-origin: 10px 10px;
    }
}