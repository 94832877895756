
// ----------------------------------
// ||            Footer            ||
// ----------------------------------


// ----------------- CSS SPECIFIQUE AU SITE -----------------

footer {
	.colonne {

	}
}

// ----------------------------------------------------------


// FOOTER - GÉNÉRAL
footer {
	display: flex;
	z-index: 1;
	background-color: $footer_bgcolor;
	padding: $footer_padding;
	.separateur { width: $footer_separateur_horizontal; }
	color: $footer_txtcolor;
	justify-content: space-between;
	@if $footer_cols_hposition == left { justify-content: left; }
	@if $footer_cols_hposition == center { justify-content: center; }
	@if $footer_cols_hposition == right { justify-content: right; }
	align-items: flex-end;
	@if $footer_cols_vposition == top { align-items: flex-start; }
	@if $footer_cols_vposition == center { align-items: center; }
	@if $footer_cols_vposition == bottom { align-items: flex-end; }

	.colonne {
		padding: $footer_cols_padding;

		p {
			margin: 0;
		}

		.logo {
			width: $footer_logo_width;
		}
	}

	a {
		color: $footer_link_color;
		@if $footer_link_underline == 1 { text-decoration: underline; }
	}
	a:hover {
		color: $footer_link_hover_color;
	}
}


@include media-breakpoint-down($responsive_breakpoint) {

	footer {
		.separateur { width: 100%; height: $footer_separateur_vertical; }
	}

}