
// ------------------------------------
// ||             Modals             ||
// ------------------------------------


// GÉNÉRAL
.modal {
    padding-right: 0px !important;
}

.modal.centered {
   .modal-dialog {
        margin: 0 !important;
        @extend %abcenter_xy;
        transform: translate(-50%, -50%) !important;
    } 
}


// MODALE MINIMALISTE, BORDERLESS ETC
.modal.bare {
    text-align: center;

    .modal-dialog {
        display: inline-block;
    	
    	.modal-content {
    		border-radius: 0 !important;
    		border: none;
            min-height: 40px;

    		button.close {
    			cursor: pointer;
    			position: absolute;
    			z-index: 100;
    			top: 10px;
    			right: 10px;
    			font-size: 3rem;
    			line-height: 0.5em;
    			color: $color_black;
    		}

    		.modal-body {
    			padding: 0;
    		}
    	}
    }
}

// MODALE AVEC CAROUSEL DEDANS
.carousel_modal {

	.modal-dialog {
		height: auto !important;
		max-width: 90vw;

		.modal-content {
			height: 100%;

            img {
                max-height: 90vh;
            }
		}
	}
}


// Modale confirmation contact
.form_error_box {
    .modal-title {
        font-weight: 700;
    }

    .form_modal_ok {
        color: $color_success;
    }

    .form_modal_fail {
        color: $color_error;
    }

    i {
        float: left;
        margin-right: 16px;
        font-size: 3rem;
        margin-bottom: 16px;
    }

    h6 {
        margin-top: 26px;
        font-weight: 700;
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            border-top: 1px solid #e0e0e0;
            font-size: 0.9rem;
            padding-top: 8px;
            margin-bottom: 10px;
        }
    }
}


// ----------------------------
// REGLAGES MODALES SPECIFIQUES

$modal_id: 'modal_normale';
$modal_txtcolor: $color_white;
$modal_bgcolor: #202020;
$modal_separator_color: #404040;
$modal_close_color: $color_white;
$modal_close_shadow: #b0b0b0;
$modal_buttons_bgcolor: $color_primary; // 0 = don't change
##{$modal_id} {

    .modal-dialog {
        color: $modal_txtcolor;
        .close {
            color: $modal_close_color;
            text-shadow: 0 1px 0 $modal_close_shadow;
        }
        .modal-content {
            background-color: $modal_bgcolor;
            .modal-header {
                border-bottom: 1px solid $modal_separator_color;
            }
            .modal-footer {
                border-top: 1px solid $modal_separator_color;
            }
            .modal-body, .modal-footer {
                button {
                    border: none;
                    @if $modal_buttons_bgcolor != 0 {
                        background-color: $color_primary;
                    }
                }
            }
        }
    }
}


$modal_id: 'modal_carousel';
$modal_vcenter: center;
$img_sizing: cover;
$modal_bgcolor: #202020;
$modal_close_color: $color_white;
$modal_close_shadow: #b0b0b0;
##{$modal_id} {

    .modal-dialog {
        @if($modal_vcenter == center) {
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        .close {
            color: $modal_close_color;
            text-shadow: 0 1px 0 $modal_close_shadow;
        }
        .modal-content {
            background-color: $modal_bgcolor;
            img {
                width: 100%;
                height: 100%;
                object-fit: $img_sizing;
            }
        }
    }
}
