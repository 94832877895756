.animated_zone .circles_container, .animated_zone .texte, .animated_zone .texte_debut, section.entete .fond, section.entete .logo, .modal.centered .modal-dialog, .carousel .carousel-indicators li.active .carousel-indicator-inside, .carousel .carousel-indicators li .carousel-indicator-inside, .nav-item.icon .nav_icon_container i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animated_zone img, section.intro .illu, section.entete .fond, .square_container .square_content img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  background-color: white;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  width: 100%;
  padding-right: 0 !important;
  font-family: "nom_police_principale", sans-serif;
  letter-spacing: 0.01rem;
  color: #000000;
}

section {
  position: relative;
  width: 100%;
}

section a {
  color: #000000;
  font-weight: 700;
  text-decoration: underline;
}

section a:visited {
  color: black;
}

textarea,
input.text,
input[type=text],
input[type=button],
input[type=submit],
div[type=submit],
*[type=submit],
button,
input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

input[type=checkbox] {
  display: inline-block;
  appearance: checkbox !important;
}

input[type=radio] {
  display: inline-block;
  min-height: 13px;
  width: auto !important;
  appearance: radio !important;
}

button, .btn, .close {
  cursor: pointer;
}

/*select {
	background-image: url("/images/select_arrow.png");
	background-position: right center;
	background-repeat: no-repeat;
}*/
*, *:focus, *:active {
  outline: none !important;
}

img {
  height: auto;
}

.full_site {
  position: relative;
  padding: 0;
}
.full_site .site_content {
  min-height: 80vh;
}

.hidden {
  display: none;
}

.admin_link {
  position: absolute;
  z-index: 10000;
  background-color: transparent;
  border-top: 26px solid #343a40;
  border-left: 26px solid #343a40;
  border-right: 26px solid transparent;
  border-bottom: 26px solid transparent;
  top: -36px;
  left: -36px;
  animation-name: admin_link_start;
  animation-duration: 0.8s;
  transition: 0.3s;
}
.admin_link i {
  color: white;
  position: absolute;
  top: -20px;
  left: -20px;
}

.admin_link:hover {
  top: 0px;
  left: 0px;
}

@keyframes admin_link_start {
  from {
    top: 0px;
    left: 0px;
  }
  to {
    top: -36px;
    left: -36px;
  }
}
.grecaptcha-badge {
  display: none;
}

.scrollnav {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 10%;
  /* transform: translateY(-50%); */
  width: 50px;
  /* background-color: rgba($color_white, 0.2); */
}
.scrollnav .scrollnav_link {
  height: 10px;
  width: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 20px 50%;
  border-radius: 1000px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.scrollnav .scrollnav_link:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.scrollnav .scrollnav_link.active {
  background-color: #0554a0;
}

.popover_content_container {
  display: none;
}

.popover {
  max-width: 350px;
}
.popover .popover-header {
  white-space: nowrap;
  color: #c0c0c0;
}
.popover .popover-header .title {
  display: inline-block;
  font-weight: 700;
}
.popover .close_pop {
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
}

.bg_image {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.altfont {
  font-family: "nom_police_alternative", sans-serif;
  letter-spacing: 0.05rem;
}

.txt_blanc {
  color: #ffffff !important;
}

.txt_noir {
  color: #000000 !important;
}

.container.small {
  max-width: 800px;
}

.container.verysmall {
  max-width: 500px;
}

.square_container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square_container {
  display: block;
  position: relative;
}
.square_container .square_content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.bloc_169e {
  position: relative;
  padding-bottom: 56.25%;
}
.bloc_169e > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

/*.bouton_type_1 {
	cursor: pointer;
	padding-bottom: 4px;
	font-size: 1.3rem;
	line-height: 1rem;
	font-weight: 700;
	color: $color_black !important;
	border-bottom: 1px solid $color_black;
	text-decoration: none;
}
.bouton_type_1:hover {
	text-decoration: none;
	border-bottom: 2px solid $color_black;
}
.bouton_type_2 {
	cursor: pointer;
	background-color: rgba($color_bleu, 0);
	border: 1px solid $color_bleu;
	border-radius: 0;
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 700;
}
.bouton_type_2:hover {
	text-decoration: none;
	border: 1px solid $color_bleu;
	background-color: rgba($color_bleu, 0.1);
}*/
@font-face {
  font-family: Archivo;
  src: url("../fonts/archivo-variablefont.ttf") format("truetype-variations");
}
@font-face {
  font-family: Fraunces;
  src: url("../fonts/fraunces-variablefont.ttf") format("truetype-variations");
}
@font-face {
  font-family: Cabin;
  src: url("../fonts/cabin-variablefont.ttf") format("truetype-variations");
}
@font-face {
  font-family: Georama;
  src: url("../fonts/georama-variablefont.ttf") format("truetype-variations");
}
@font-face {
  font-family: Alumni Sans;
  src: url("../fonts/alumnisans-variablefont.ttf") format("truetype-variations");
}
@font-face {
  font-family: Literata;
  src: url("../fonts/literata-variablefont.ttf") format("truetype-variations");
}
.variable_fonts {
  background-color: #222;
}
.variable_fonts * {
  z-index: 10;
  cursor: initial;
  user-select: none;
  color: white;
}
.variable_fonts .fonts_container {
  position: relative;
  width: 500px;
  height: 600px;
  margin: auto;
}
.variable_fonts .titre1 {
  position: absolute;
  top: 100px;
  right: 120px;
  font-family: Literata;
  font-size: 6rem;
  font-variation-settings: "wght" 100;
  letter-spacing: 0rem;
  transition: 0.4s;
}
.variable_fonts .titre1:hover {
  letter-spacing: 0.2rem;
  font-variation-settings: "wght" 900;
}
.variable_fonts .titre2 {
  position: absolute;
  top: 120px;
  right: -40px;
  transform: rotate(90deg);
  font-family: "Georama", sans-serif;
  font-size: 9rem;
  font-variation-settings: "wght" 10, "wdth" 150;
  transition: 0.4s;
}
.variable_fonts .titre2:hover {
  font-variation-settings: "wght" 900, "wdth" 120;
  right: -30px;
}
.variable_fonts .titre3 {
  position: absolute;
  top: 200px;
  left: 0;
  font-family: "Fraunces", sans-serif;
  font-size: 6rem;
  font-variation-settings: "wght" 900, "SOFT" 100;
  letter-spacing: 0rem;
  transition: 0.4s;
}
.variable_fonts .titre3:hover {
  letter-spacing: 0.4rem;
  font-variation-settings: "wght" 700, "SOFT" 0;
}
.variable_fonts .titre4 {
  position: absolute;
  top: 470px;
  left: 30px;
  transform-origin: left center;
  transform: rotate(-90deg);
  font-family: "Georama", sans-serif;
  font-size: 5rem;
  font-variation-settings: "wght" 10, "wdth" 100;
  transition: 0.4s;
}
.variable_fonts .titre4:hover {
  font-variation-settings: "wght" 900, "wdth" 95;
}
.variable_fonts .titre5 {
  position: absolute;
  top: 290px;
  left: 70px;
  font-family: "Literata", sans-serif;
  font-size: 5rem;
  font-variation-settings: "wght" 900, "wdth" 100;
  transition: 0.4s;
}
.variable_fonts .titre5:hover {
  font-variation-settings: "wght" 200, "wdth" 95;
}

.fonts {
  font-family: "Cabin", sans-serif;
  font-family: "Fraunces", serif;
  font-family: "Georama", sans-serif;
}

.navbar {
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.3);
}
.navbar .container {
  max-width: none !important;
  padding: 0;
}
.navbar .container .etage {
  width: 100%;
}
.navbar .navbar_collapsed_header {
  display: none;
}

.navbar-brand {
  height: 40px;
}
.navbar-brand img {
  display: block;
  height: 100%;
  width: auto;
}

.logo_in_collapse {
  display: none;
}

.navbar-brand-floating {
  display: none;
}

.nav-item:first-child {
  margin-left: auto;
}

.nav-item {
  position: relative;
  margin: 0px 6px;
}
.nav-item .nav-link {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.9rem;
  font-weight: 500;
  color: #0554a0;
  background-color: transparent;
  border-radius: 0px;
  padding: 0.25rem 0.25rem !important;
  transition: 0.25s;
  text-transform: uppercase;
}
.nav-item .nav-underline {
  position: absolute;
  bottom: 0;
  background-color: #a0a050;
  transition: 0.25s;
  height: 4px;
  width: 0;
  left: 0;
}

.nav-item:hover .nav-link, .nav-item:active .nav-link, .nav-item.active .nav-link, .nav-item.show .nav-link {
  color: #033a6f;
  background-color: transparent;
  border-radius: 0px;
}
.nav-item:hover .nav-underline, .nav-item:active .nav-underline, .nav-item.active .nav-underline, .nav-item.show .nav-underline {
  width: 50%;
}

.nav-item.icon {
  margin: 0 10px !important;
}
.nav-item.icon .nav-link {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.nav-item.icon .nav-link:hover {
  background-color: transparent !important;
}
.nav-item.icon .nav_icon_container {
  border: 1px solid #0554a0;
  border-radius: 1000px;
  padding: 16px;
  background-color: transparent;
  height: 20px;
  width: 20px;
  transition: 0.2s;
}
.nav-item.icon .nav_icon_container i {
  color: #0554a0;
  transition: 0.25s;
  font-size: 1.1rem;
}

.nav-item:hover .nav_icon_container {
  border: 1px solid #0554a0;
  border-radius: 1000px;
  background-color: rgba(5, 84, 160, 0.3);
}
.nav-item:hover i {
  color: #033a6f;
}

.nav-item .dropdown-menu {
  padding: 0 0;
  background-color: #909090;
  border-radius: 0 !important;
  border: 0 solid transparent;
  box-shadow: none !important;
  top: 100%;
  transition: 0.5s;
  display: block !important;
  opacity: 0;
  pointer-events: none;
  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
}
.nav-item .dropdown-menu a {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 1rem 0.5rem;
  text-align: left;
}
.nav-item .dropdown-menu a:hover {
  color: #cccccc;
  background-color: #505050;
}

.nav-item .dropdown-menu.show {
  opacity: 1;
  pointer-events: auto;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(-10px);
}

/* $navbar_toggler_ratio: $navbar_togglericon_height / $navbar_togglericon_width;
@if $navbar_toggler_ratio < 0.5 { $togglerbar_angle: 25deg; } */
.navbar-toggler {
  z-index: 100;
  cursor: pointer;
  background-color: #ffffff;
  font-size: 16px;
  border: 0px solid transparent;
  border-radius: 0px;
}
.navbar-toggler .navbar-toggler-icon {
  position: relative;
  width: 30px;
}
.navbar-toggler .navbar-toggler-icon .toggler-bar {
  position: absolute;
  width: 100%;
  left: 0;
  height: 4px;
  background-color: #505080;
  transform-origin: center center;
  transform: translateY(-50%);
  transition: 0.2s;
}
.navbar-toggler .navbar-toggler-icon .top-bar {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.navbar-toggler .navbar-toggler-icon .middle-bar {
  top: 50%;
  opacity: 0;
  transition: 0.2s;
}
.navbar-toggler .navbar-toggler-icon .bottom-bar {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.navbar-toggler.collapsed {
  transform: translateY(0%);
}
.navbar-toggler.collapsed .navbar-toggler-icon .top-bar {
  top: 0;
  transform: translateY(0%) rotate(0deg);
}
.navbar-toggler.collapsed .navbar-toggler-icon .middle-bar {
  opacity: 1;
  transition: 0.2s;
}
.navbar-toggler.collapsed .navbar-toggler-icon .bottom-bar {
  top: 100%;
  transform: translateY(-100%) rotate(0deg);
}

@media (max-width: 1199.98px) {
  .navbar {
    padding: 0;
    /* .nav-item:first-child {
    	margin: $navbar_links_margin;
    }
    .nav-item:last-child {
    	margin: $navbar_links_margin;
    } */
  }
  .navbar .navbar-brand, .navbar .navbar-toggler {
    z-index: 10;
  }
  .navbar .logo_in_collapse {
    display: block;
  }
  .navbar .puller {
    display: none;
  }
  .navbar .nav-underline {
    display: none;
  }
  .navbar .navbar-collapse {
    order: 3;
    background-color: #0554a0;
    width: 100%;
    transition: 0.5s;
    transition-delay: 0;
    /* .filler {
    	position: absolute;
    	pointer-events: none;
    	height: 100vh;
    	width: 100%;
    } */
    top: 0;
    bottom: auto;
  }
  .navbar .navbar-collapse .container {
    display: flex;
    height: 100vh;
    /* width: 100%; */
    justify-content: center;
    align-items: center;
  }
  .navbar .navbar-collapse .container .col {
    padding: 5vw;
    flex-grow: initial;
    align-self: center;
  }
  .navbar .navbar-collapse .container .col .etage {
    padding: 0;
    width: 100%;
    flex-grow: initial;
  }
  .navbar .navbar-collapse .container .col .etage img {
    width: 100%;
    height: auto;
  }
  .navbar .navbar-collapse .container .navbar-nav {
    display: inline-block;
    /* @if $navbar_collapse_vertical_align == top { align-self: flex-start; }
    @if $navbar_collapse_vertical_align == bottom { align-self: flex-end; } */
    text-align: left;
  }
  .navbar .navbar-collapse .container .navbar-nav a {
    font-size: 1.4rem !important;
    font-weight: 300;
  }
  .navbar .navbar-collapse .container .navbar-nav .nav-item.icon {
    display: inline-block;
  }
  .navbar .navbar-collapse .container .navbar-nav .nav-link {
    color: #ffffff;
    transform: none;
  }
  .navbar .navbar-collapse .container .navbar-nav .nav-link .nav_icon_container {
    border-color: #ffffff;
  }
  .navbar .navbar-collapse .container .navbar-nav .nav-link .nav_icon_container i {
    color: #ffffff;
  }

  .navbar-brand {
    order: 1;
  }

  .navbar-toggler {
    order: 2;
  }

  .navbar-brand {
    display: none;
  }

  .background_nobar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0px;
    background-color: #ffffff;
  }

  .navbar {
    padding: 0;
    background-color: transparent;
  }

  .navbar-toggler {
    position: fixed;
    top: 0px;
    right: 0px;
  }

  .navbar-collapse {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }

  .navbar-brand-floating {
    position: fixed;
    z-index: 1001;
    display: block;
    width: 100px;
    top: 4px;
    left: 8px;
  }
  .navbar-brand-floating img {
    width: 100%;
  }

  .nav-item .dropdown-menu {
    position: relative;
    transition: none;
    display: block;
    opacity: 1;
    transform: none;
    padding-top: 0;
    background: none;
    background-color: none;
    padding-left: 30px;
  }
}
#cookie_notice {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  overflow: hidden;
  background-color: #0554a0;
  max-height: 200px;
  opacity: 1;
  transition: 0.3s;
  width: 100%;
}
#cookie_notice * {
  margin: 0;
  font-size: 0.9rem;
}
#cookie_notice .flex_container {
  display: flex;
  align-items: flex-end;
  padding: 10px 20px;
}
#cookie_notice .texte {
  flex: 1;
  color: #ffffff;
}
#cookie_notice .lien {
  flex: initial;
  padding: 0 15px;
}
#cookie_notice .lien a {
  color: #ffffff;
  text-decoration: underline;
}
#cookie_notice .bouton {
  flex: initial;
}
#cookie_notice .bouton button {
  cursor: pointer;
  color: #ffffff;
  background-color: #4e6512;
  transition: 0.1s;
}
#cookie_notice .bouton button:hover {
  background-color: #5f7b16;
}

#cookie_notice.hide {
  max-height: 0;
}

.cookies_text {
  padding: 4vw;
}
.cookies_text p {
  margin-bottom: 20px;
}
.cookies_text h6 {
  margin-top: 30px;
}
.cookies_text h6:first-child {
  margin-top: 0px;
}
.cookies_text table td {
  padding-right: 20px;
}

@media (max-width: 1199.98px) {
  #cookie_notice {
    max-height: 400px;
  }
  #cookie_notice .flex_container {
    flex-direction: column;
    align-items: flex-start;
  }
  #cookie_notice .flex_container > div {
    margin-bottom: 16px;
  }
  #cookie_notice .lien {
    padding: 0;
  }
}
footer {
  display: flex;
  z-index: 1;
  background-color: #ffffff;
  padding: 3vw;
  color: #000000;
  justify-content: space-between;
  align-items: flex-end;
  align-items: flex-end;
}
footer .separateur {
  width: 20px;
}
footer .colonne {
  padding: 0 30px;
}
footer .colonne p {
  margin: 0;
}
footer .colonne .logo {
  width: 130px;
}
footer a {
  color: #000000;
  text-decoration: underline;
}
footer a:hover {
  color: #000000;
}

@media (max-width: 1199.98px) {
  footer .separateur {
    width: 100%;
    height: 30px;
  }
}
form.custom-form label {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
  font-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
}
form.custom-form input:focus, form.custom-form textarea:focus {
  color: #0554a0 !important;
  background-color: #e0e0e0 !important;
  border: none !important;
}
form.custom-form .form-check-label {
  font-size: 1rem;
  font-weight: 500;
  font-style: none;
  margin-bottom: 0px;
  padding-left: 1.25rem;
}
form.custom-form .form-control {
  padding: 0.375rem 0.75rem;
  border: none;
  border-radius: 0;
  color: #0554a0;
  background-color: #dddddd;
  font-size: 1rem;
  font-weight: 500;
  font-style: none;
  margin-bottom: 0px;
}
form.custom-form .form-control.green {
  border: 2px solid #008000;
}
form.custom-form .form-control.red {
  border: 2px solid #a00000;
}
form.custom-form .form-control::placeholder {
  color: rgba(5, 84, 160, 0.6);
}
form.custom-form .form-control:-ms-input-placeholder {
  color: rgba(5, 84, 160, 0.6);
}
form.custom-form .form-control::-ms-input-placeholder {
  color: rgba(5, 84, 160, 0.6);
}
form.custom-form select.form-control, form.custom-form select.form-control:focus {
  color: #ffffff;
  background-color: #0554a0;
}
form.custom-form select.form-control *, form.custom-form select.form-control:focus * {
  color: #ffffff;
  background-color: #0554a0;
}
form.custom-form .submit_container {
  text-align: center;
}
form.custom-form .submit_container button {
  width: auto;
  padding: 0.2rem 1rem;
  color: #ffffff;
  background-color: #0554a0;
  font-size: 1.1rem;
  font-weight: 700;
  border-radius: 0.25rem;
}
form.custom-form .submit_container button:hover {
  background-color: #076ed1;
}
form.custom-form .loader {
  pointer-events: none;
  position: absolute;
  z-index: 1000;
  width: 100px;
  height: 100px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1000px;
}
form.custom-form .loader:after {
  content: " ";
  display: block;
  width: 80px;
  height: 80px;
  margin: 10px;
  border-radius: 50%;
  border: 10px solid #ffffff;
  border-color: #ffffff transparent #ffffff transparent;
  animation: loader 3s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.carousel {
  position: relative;
}
.carousel .content_over_carousel {
  position: absolute;
  z-index: 10;
}
.carousel .carousel-inner {
  position: relative;
  height: 100%;
  width: 100%;
}
.carousel .carousel-inner .carousel-item {
  height: 100%;
  width: 100%;
  /*img {
  	position: absolute;
  	z-index: -100;
  	top: 0; left: 0;
  	width: 100%; height: 100%;
  	object-fit: cover;
  }*/
}
.carousel .carousel-indicators {
  cursor: pointer;
  margin: 0;
}
#carousel_exemple {
  height: 40vw;
  width: 100%;
}
#carousel_exemple .carousel-control {
  width: 10%;
}
#carousel_exemple .carousel-control-prev {
  font-size: 2rem;
}
#carousel_exemple .carousel-control-next {
  font-size: 2rem;
}
#carousel_exemple .carousel-indicators {
  transition: 0.5s;
  bottom: 30px;
}
#carousel_exemple .carousel-indicators li {
  transition: 0.5s;
  height: 12px;
  width: 12px;
  margin: 0 3px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffffff;
  border-radius: 1000px;
}
#carousel_exemple .carousel-indicators li .carousel-indicator-inside {
  transition: 0.5s;
  border-radius: 1000px;
  height: 0px;
  width: 0px;
  background-color: rgba(255, 255, 255, 0);
}
#carousel_exemple .carousel-indicators li.active {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffffff;
}
#carousel_exemple .carousel-indicators li.active else {
  border: keep;
}
#carousel_exemple .carousel-indicators li.active .carousel-indicator-inside {
  height: 6px;
  width: 6px;
  background-color: white;
}

#carousel_modal {
  height: auto;
  width: 100%;
}
#carousel_modal .carousel-control {
  width: 10%;
}
#carousel_modal .carousel-control-prev {
  font-size: 2rem;
}
#carousel_modal .carousel-control-next {
  font-size: 2rem;
}
#carousel_modal .carousel-indicators {
  transition: 0.5s;
  bottom: 30px;
}
#carousel_modal .carousel-indicators li {
  transition: 0.5s;
  height: 12px;
  width: 12px;
  margin: 0 3px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffffff;
  border-radius: 1000px;
}
#carousel_modal .carousel-indicators li .carousel-indicator-inside {
  transition: 0.5s;
  border-radius: 1000px;
  height: 0px;
  width: 0px;
  background-color: rgba(255, 255, 255, 0);
}
#carousel_modal .carousel-indicators li.active {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffffff;
}
#carousel_modal .carousel-indicators li.active else {
  border: keep;
}
#carousel_modal .carousel-indicators li.active .carousel-indicator-inside {
  height: 6px;
  width: 6px;
  background-color: white;
}

.modal {
  padding-right: 0px !important;
}

.modal.centered .modal-dialog {
  margin: 0 !important;
  transform: translate(-50%, -50%) !important;
}

.modal.bare {
  text-align: center;
}
.modal.bare .modal-dialog {
  display: inline-block;
}
.modal.bare .modal-dialog .modal-content {
  border-radius: 0 !important;
  border: none;
  min-height: 40px;
}
.modal.bare .modal-dialog .modal-content button.close {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  font-size: 3rem;
  line-height: 0.5em;
  color: #000000;
}
.modal.bare .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.carousel_modal .modal-dialog {
  height: auto !important;
  max-width: 90vw;
}
.carousel_modal .modal-dialog .modal-content {
  height: 100%;
}
.carousel_modal .modal-dialog .modal-content img {
  max-height: 90vh;
}

.form_error_box .modal-title {
  font-weight: 700;
}
.form_error_box .form_modal_ok {
  color: #008000;
}
.form_error_box .form_modal_fail {
  color: #a00000;
}
.form_error_box i {
  float: left;
  margin-right: 16px;
  font-size: 3rem;
  margin-bottom: 16px;
}
.form_error_box h6 {
  margin-top: 26px;
  font-weight: 700;
}
.form_error_box ul {
  padding: 0;
  list-style: none;
}
.form_error_box ul li {
  border-top: 1px solid #e0e0e0;
  font-size: 0.9rem;
  padding-top: 8px;
  margin-bottom: 10px;
}

#modal_normale .modal-dialog {
  color: #ffffff;
}
#modal_normale .modal-dialog .close {
  color: #ffffff;
  text-shadow: 0 1px 0 #b0b0b0;
}
#modal_normale .modal-dialog .modal-content {
  background-color: #202020;
}
#modal_normale .modal-dialog .modal-content .modal-header {
  border-bottom: 1px solid #404040;
}
#modal_normale .modal-dialog .modal-content .modal-footer {
  border-top: 1px solid #404040;
}
#modal_normale .modal-dialog .modal-content .modal-body button, #modal_normale .modal-dialog .modal-content .modal-footer button {
  border: none;
  background-color: #0554a0;
}

#modal_carousel .modal-dialog {
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#modal_carousel .modal-dialog .close {
  color: #ffffff;
  text-shadow: 0 1px 0 #b0b0b0;
}
#modal_carousel .modal-dialog .modal-content {
  background-color: #202020;
}
#modal_carousel .modal-dialog .modal-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

textarea,
input.text,
input[type=text],
input[type=button],
input[type=submit],
div[type=submit],
*[type=submit],
button,
input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

input[type=checkbox] {
  display: inline-block;
  appearance: checkbox !important;
}

input[type=radio] {
  min-height: 13px;
  appearance: radio !important;
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #000;
  width: 300px;
  height: 40px;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0554a0;
  padding: 8px 15px;
  border: 1px solid #032d56;
  transition: all 0.2s ease-in;
}
@media (prefers-reduced-motion: reduce) {
  .select-styled {
    transition: none;
  }
}
.select-styled:after {
  content: "";
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-color: #000 transparent transparent transparent;
  position: absolute;
  top: 16px;
  right: 10px;
}
.select-styled:hover {
  background-color: #044a8c;
}
.select-styled:active, .select-styled.active {
  background-color: #044787;
}
.select-styled:active:after, .select-styled.active:after {
  top: 9px;
  border-color: transparent transparent #000 transparent;
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #044787;
}
.select-options li {
  margin: 0;
  padding: 12px 0;
  text-indent: 15px;
  border-top: 1px solid #033a6f;
  transition: all 0.15s ease-in;
}
@media (prefers-reduced-motion: reduce) {
  .select-options li {
    transition: none;
  }
}
.select-options li:hover {
  color: #0554a0;
  background: #000;
}
.select-options li[rel=hide] {
  display: none;
}

.fixswitch_container {
  position: relative;
}
.fixswitch_container .fixswitch_element {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  height: 100vh;
}
.fixswitch_container.fixed .fixswitch_element {
  position: fixed;
  top: 0;
  bottom: 0;
}
.fixswitch_container.absolute_top .fixswitch_element {
  top: 0;
  bottom: auto;
}
.fixswitch_container.absolute_bottom .fixswitch_element {
  top: auto;
  bottom: 0;
}

.borlabs_generator_page .copied {
  pointer-events: none;
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: black;
  color: white;
  padding: 4px 16px;
  border-radius: 4px;
  opacity: 0;
  transition: 1s;
}
.borlabs_generator_page .copied.visible {
  opacity: 1;
  transition: 0s;
}
.borlabs_generator_page .page_title {
  margin-top: 0;
}
.borlabs_generator_page .page_title strong {
  font-weight: 900;
}
.borlabs_generator_page .infos {
  margin-bottom: 40px;
}
.borlabs_generator_page .tools_container {
  max-width: 1000px;
  margin: auto;
  margin-top: 40px;
}
.borlabs_generator_page .generator_controls {
  padding: 30px;
  background-color: #ccc;
  margin-bottom: 20px;
  border-radius: 10px;
}
.borlabs_generator_page .generator_controls .cols {
  display: flex;
  justify-content: space-between;
}
.borlabs_generator_page .generator_controls .cols > * {
  margin-right: 20px;
}
.borlabs_generator_page .generator_controls .form-group {
  margin-bottom: 10px;
}
.borlabs_generator_page .generator_controls h3 {
  margin-top: 0;
}
.borlabs_generator_page .generator_controls label {
  display: block;
  font-size: 0.8rem;
}
.borlabs_generator_page .generator_controls input {
  border: 1px solid black;
  padding: 4px 8px;
}
.borlabs_generator_page .generator_controls input::placeholder {
  color: #bbb;
}
.borlabs_generator_page .generator_controls input:active, .borlabs_generator_page .generator_controls input:focus {
  border: 1px solid black;
}
.borlabs_generator_page .generator_controls .btn_container button {
  border: none;
  width: 100%;
  padding: 15px;
  color: white;
  background-color: black;
}
.borlabs_generator_page .generated_elements {
  padding: 30px;
  background-color: #ccc;
  border-radius: 10px;
}
.borlabs_generator_page .generated_elements textarea {
  width: 100%;
  min-height: 200px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: arial narrow;
}

.lien_trait_ondule {
  display: inline-block;
  text-decoration: none;
  transition: 0.1s;
}
.lien_trait_ondule .line {
  display: flex;
  align-items: center;
}
.lien_trait_ondule #svg_trait_ondule {
  background-color: transparent;
}
.lien_trait_ondule #svg_trait_ondule #path_trait_ondule {
  fill: transparent;
  stroke: #000;
  stroke-width: 3px;
  stroke-linecap: round;
  transition: 0.15s;
}
.lien_trait_ondule .label {
  margin-left: 5px;
  line-height: 1;
  font-size: 1.1rem;
  text-transform: uppercase;
  white-space: nowrap;
}
.lien_trait_ondule .label .char {
  transform: translateY(0px);
  transition: transform 0.1s ease-out;
}
.lien_trait_ondule:hover {
  text-decoration: none;
  color: #ffc533;
}
.lien_trait_ondule:hover .label .char {
  animation: 0.7s ease-out 0s 1 forwards;
  animation-name: anim_char;
  animation-delay: calc(0.03s * var(--char-index));
}
@keyframes anim_char {
  0% {
    transform: translateY(0px);
  }
  15% {
    transform: translateY(-10px);
  }
  30% {
    transform: translateY(8px);
  }
  45% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(4px);
  }
  85% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
.lien_trait_ondule:hover #svg_trait_ondule #path_trait_ondule {
  stroke: #ffc533;
}

section.entete {
  height: 100vw;
  max-height: 80vh;
}
section.entete .logo {
  height: 80%;
  max-width: 90vw;
}
section.entete .fond {
  z-index: -1;
}

section.intro {
  margin-bottom: 80px;
}
section.intro .right {
  padding: 4vw 7vw;
}
@media (max-width: 1199.98px) {
  section.intro .right {
    padding: 30px 40px;
  }
}
section.intro .titre {
  font-size: calc(2rem + 3vw);
  font-weight: 700;
  line-height: 1;
  margin: 0;
}
section.intro .soustitre {
  font-size: calc(0.7rem + 1.3vw);
  font-weight: 700;
  margin-bottom: 30px;
}
section.intro p {
  line-height: 1.2;
}
section.intro .pictos_ab_ecocert {
  height: 60px;
}
@media (max-width: 1199.98px) {
  section.intro {
    margin-bottom: 100px;
  }
  section.intro .right {
    order: 1;
  }
  section.intro .left {
    order: 2;
  }
}

.product_bloc {
  padding: calc(20px + 4vw) 0;
}
.product_bloc .title_side {
  padding: 7vw;
}
@media (max-width: 1199.98px) {
  .product_bloc .title_side {
    margin-top: 30px;
  }
}
.product_bloc h2 {
  font-size: calc(2rem + 3vw);
  font-weight: 700;
}
.product_bloc .bouteille {
  position: absolute;
  z-index: 1;
  height: calc(100% + 10vw);
  top: 50%;
  transform: translateY(-50%);
}
.product_bloc .parallax_frame {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 340px;
}
.product_bloc .parallax_frame img {
  position: absolute;
}
.product_bloc:nth-child(odd) .bouteille {
  left: 12%;
}
@media (min-width: 992px) {
  .product_bloc:nth-child(odd) .title_side {
    order: 1;
  }
  .product_bloc:nth-child(odd) .image_side {
    order: 2;
  }
}
.product_bloc:nth-child(even) .bouteille {
  right: 12%;
}
.product_bloc:nth-child(1) .parallax_frame {
  background-color: #ffc533;
}
.product_bloc:nth-child(1) .lien_trait_ondule:hover {
  color: #ffc533;
}
.product_bloc:nth-child(1) .lien_trait_ondule:hover #svg_trait_ondule #path_trait_ondule {
  stroke: #ffc533;
}
.product_bloc:nth-child(2) .parallax_frame {
  background-color: #945824;
}
.product_bloc:nth-child(2) .lien_trait_ondule:hover {
  color: #945824;
}
.product_bloc:nth-child(2) .lien_trait_ondule:hover #svg_trait_ondule #path_trait_ondule {
  stroke: #945824;
}
.product_bloc:nth-child(3) .parallax_frame {
  background-color: #afce4d;
}
.product_bloc:nth-child(3) .lien_trait_ondule:hover {
  color: #afce4d;
}
.product_bloc:nth-child(3) .lien_trait_ondule:hover #svg_trait_ondule #path_trait_ondule {
  stroke: #afce4d;
}
.product_bloc:nth-child(4) .parallax_frame {
  background-color: #ffe69b;
}
.product_bloc:nth-child(4) .lien_trait_ondule:hover {
  color: #ffe69b;
}
.product_bloc:nth-child(4) .lien_trait_ondule:hover #svg_trait_ondule #path_trait_ondule {
  stroke: #ffe69b;
}
.product_bloc.banane_ananas .banane {
  width: 80%;
  top: -40%;
  right: 5%;
}
.product_bloc.banane_ananas .ananas {
  width: 45%;
  right: 15%;
  bottom: 15%;
}
.product_bloc.kiwi_cacao .kiwi1 {
  width: 30%;
  left: 35%;
  top: -25%;
}
.product_bloc.kiwi_cacao .kiwi2 {
  width: 40%;
  left: 7%;
  bottom: -20%;
}
.product_bloc.kiwi_cacao .grains_cafe1 {
  width: 70%;
  right: 15%;
}
.product_bloc.kiwi_cacao .grains_cafe2 {
  width: 70%;
  right: 15%;
}

.test_ugp * {
  font-family: "Roboto", sans-serif !important;
}

.animated_zone {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  clip-path: inset(0 0 0 0);
}
.animated_zone img {
  z-index: -1;
  clip-path: circle(100% at 50% 50%);
}
.animated_zone .texte_debut {
  z-index: 15;
  text-align: center;
  color: white;
  white-space: nowrap;
}
.animated_zone .texte_debut h1 {
  font-family: Arial;
  line-height: 1;
}
.animated_zone .texte_debut h1 .bold {
  font-weight: 700;
}
.animated_zone .texte_debut h1 .thin {
  font-weight: 100;
}
.animated_zone .texte_debut .soustitre {
  font-size: 1.4rem;
  font-weight: 700;
}
.animated_zone .texte {
  z-index: 15;
  font-size: 25vw;
  font-weight: 100;
  color: white;
  opacity: 0;
}
.animated_zone .texte p {
  margin: 0;
  line-height: 1;
  text-align: center;
}
.animated_zone .texte p:nth-child(1) {
  transform: translateX(-100px);
}
.animated_zone .texte p:nth-child(2) {
  transform: translateX(100px);
}
.animated_zone .texte p:nth-child(3) {
  transform: translateX(-150px);
}
.animated_zone .texte p:nth-child(4) {
  transform: translateX(50px);
}
.animated_zone .texte p:nth-child(5) {
  transform: translateX(-100px);
}
.animated_zone .circles_container {
  z-index: 10;
  width: 180vw;
  height: 180vw;
  border-radius: 50%;
  transform: translate(-50%, -50%) rotate(-60deg);
}
.animated_zone .circles_container .circle {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: rgba(153, 102, 51, 0.2);
  backdrop-filter: blur(15px);
}
.animated_zone .circles_container .circle.yellow {
  z-index: 15;
  top: -50px;
  left: 40%;
}
.animated_zone .circles_container .circle.black {
  z-index: 13;
  left: 0%;
  bottom: 0%;
}
.animated_zone .circles_container .circle.white {
  z-index: 14;
  right: 0%;
  bottom: 0%;
}
.animated_zone .circles_container .circle.outerblack {
  z-index: 16;
  top: 50%;
  left: 50%;
  backdrop-filter: initial;
  background-color: transparent;
  border: 1px solid black;
  height: 0px;
  width: 0px;
  opacity: 1;
}

.screenblock {
  display: block;
  width: 100%;
  height: 100vh;
}
.screenblock .half {
  position: absolute;
  top: 50%;
}
.screenblock .double {
  position: absolute;
  top: 0;
  height: 200%;
}

.scroll_filler {
  margin-top: 200px;
  color: #eee;
}

.texte_remplissage {
  text-align: center;
  padding: 0 30px;
}
.texte_remplissage h1 {
  font-family: Arial;
  line-height: 1;
}
.texte_remplissage h1 .bold {
  font-weight: 700;
}
.texte_remplissage h1 .thin {
  font-weight: 100;
}
.texte_remplissage .soustitre {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 30px;
}
.texte_remplissage .titre_thin {
  font-size: 16vw;
  font-weight: 100;
  line-height: 1;
  margin-bottom: 40px;
}
.texte_remplissage .soustitre_bold {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 1.2;
}
.texte_remplissage .texte {
  text-align: left;
}

.fond {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
}

.fond_ugp {
  background-image: url("../images/ugp/fond_test.png");
}

.animated_zone {
  top: 70vh;
}

.svg_container .main_svg {
  width: 100%;
}
.svg_container .main_svg path, .svg_container .main_svg ellipse {
  stroke-width: 0.5px;
  stroke: black;
  fill: none;
}
.svg_container .main_svg .cls- {
  stroke: red;
}
.svg_container .boule {
  width: 120px;
}

.div_with_lag_1 {
  position: absolute;
  z-index: 1;
  top: 50vh;
  left: 100px;
  padding: 30px;
  background-color: green;
  font-size: 1.3rem;
  font-weight: 700;
}

.div_with_lag_2 {
  position: absolute;
  z-index: 1;
  top: 50vh;
  right: 100px;
  padding: 30px;
  background-color: lightblue;
  font-size: 1.3rem;
  font-weight: 700;
}

.div_with_parallax_1 {
  position: absolute;
  z-index: 1;
  top: 100vh;
  right: 100px;
  padding: 30px;
  background-color: green;
  font-size: 1.3rem;
  font-weight: 700;
}

.div_with_parallax_2 {
  position: absolute;
  z-index: 1;
  top: 100vh;
  left: 100px;
  padding: 30px;
  background-color: lightgreen;
  font-size: 1.3rem;
  font-weight: 700;
}

.test_carres {
  position: absolute;
  z-index: 10;
  top: 130vh;
  left: 5%;
  right: 5%;
  height: 120vh;
}
.test_carres .carre {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.flowerbutton_container {
  position: relative;
  width: 50%;
  margin: auto;
}
.flowerbutton_container button {
  position: absolute;
  background-color: green;
  top: 37.5%;
  left: 19%;
  width: 50%;
  height: 18%;
  border: none;
}
.flowerbutton_container circle {
  transform-origin: 10px 10px;
}

/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
  display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
  position: relative;
}

/**
 * Populate the psuedo elements with the character to allow for expanded effects
 * Set to `display: none` by default; just add `display: block` when you want
 * to use the psuedo elements
 */
.splitting .char::before,
.splitting .char::after {
  content: attr(data-char);
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: inherit;
  user-select: none;
}

/* Expanded CSS Variables */
.splitting {
  /* The center word index */
  --word-center: calc((var(--word-total) - 1) / 2);
  /* The center character index */
  --char-center: calc((var(--char-total) - 1) / 2);
  /* The center character index */
  --line-center: calc((var(--line-total) - 1) / 2);
}

.splitting .word {
  /* Pecent (0-1) of the word's position */
  --word-percent: calc(var(--word-index) / var(--word-total));
  /* Pecent (0-1) of the line's position */
  --line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
  /* Percent (0-1) of the char's position */
  --char-percent: calc(var(--char-index) / var(--char-total));
  /* Offset from center, positive & negative */
  --char-offset: calc(var(--char-index) - var(--char-center));
  /* Absolute distance from center, only positive */
  --distance: calc(
     (var(--char-offset) * var(--char-offset)) / var(--char-center)
  );
  /* Distance from center where -1 is the far left, 0 is center, 1 is far right */
  --distance-sine: calc(var(--char-offset) / var(--char-center));
  /* Distance from center where 1 is far left/far right, 0 is center */
  --distance-percent: calc((var(--distance) / var(--char-center)));
}