
// -----------------------------------
// ||            General            ||
// -----------------------------------

$responsive_breakpoint: 'lg';
$fullsite_padding: 0;

// Couleurs
$color_white: #ffffff;
$color_black: #000000;
$main_bg_color: white;
$color_success: #008000;
$color_error: #a00000;
$color_primary: #0554a0;
$color_secondary: #4e6512;


// Liens
$links_color: $color_black;
$links_weight: 700;
$links_decoration: underline;


// ----------------------------------
// ||            Navbar            ||
// ----------------------------------

// Dropdown on hover -> _settings.php
// Collapse horizontal -> _settings.php

// Général
$navbar_collapse_breakpoint: 'lg'; // Attention, changer aussi dans _settings.php ! Always collapsed : xl
$navbar_contained: 0; // Limiter la largeur des navlinks avec un container (0 ou 1)
$navbar_fixed_decalage: 60px;
$navbar_position: classic; // Mettre la navbar en fixed ou en classic. Valeurs : fixed_top, fixed_bottom, classic
$navbar_width: 100%; // En fixed, utiliser "auto" pour que la navcbar fasse la largeur minimum nécessaire
$navbar-padding-y: 0.6rem;
$navbar-padding-x: 1rem;
$navbar_bgcolor: #ffffff;
$navbar_bggradient: 0; // 0 pour désactiver le gradient. Synthaxe : linear-gradient(to right, color, color, color)
$navbar_shadow_height: 0; // 0 = désactive la shadow
$navbar_shadow_opacity: 0.3;
$navbar_links_align: right; // Valeurs : left, center, right

// Logo
$navbar_brand_height: 40px;
$navbar_brand_vertical_position: middle; // Valeurs : top, middle, bottom

// Apparence des liens
$navbar_links_fontsize: 0.9rem;
$navbar_links_fontweight: 500;
$navbar_links_color: $color_primary;
$navbar_links_bgcolor: transparent;
$navbar_links_border_radius: 0px;
$navbar_links_padding-x: 0.25rem;
$navbar_links_padding-y: 0.25rem;
$navbar_links_margin: 0px 6px;
$navbar_links_forceuppercase: 1;
// Hover des liens
$navbar_links_color_hover: darken($color_primary, 10%);
$navbar_links_bgcolor_hover: transparent;
$navbar_links_border_radius_hover: 0px;
$navbar_links_animtime: 0.25s;

// Dropdowns
$navbar_dropdown_position: below; // above = par dessus le lien d'ouverture, below = calé sous le lien, 
$navbar_dropdown_bgcolor: #909090;
$navbar_dropdown_padding-x: 0;
$navbar_dropdown_padding-y: 0;
$navbar_dropdown_bordersize: 0;
$navbar_dropdown_bordercolor: transparent;
$navbar_dropdown_borderradius: 0;
$navbar_dropdown_shadow: none; // Synthaxe :  -6px 6px 12px rgba(0, 0, 0, 0.4)
$navbar_dropdown_link_color: #ffffff;
$navbar_dropdown_link_colorhover: #cccccc;
$navbar_dropdown_links_bghover: #505050;
$navbar_dropdown_links_forceuppercase: 1;
$navbar_dropdown_links_padding-x: 0.5rem;
$navbar_dropdown_links_padding-y: 1rem;
$navbar_dropdown_links_align: left; // Valeurs : left, right, center
$navbar_dropdown_animation_type: opacify; // Types possibles : none, unfold (conseil: padding dropdown 0), opacify, slide, slide_fixed_title
$navbar_dropdown_animation_time: 0.5s;


// Nav underline (hover)
$navbar_underline_use: 1; // Activer ou désactiver l'underline (0 ou 1)
$navbar_underline_color: #a0a050;
$navbar_underline_popdirection: left; // Valeurs : left, right, center, bottom
$navbar_underline_decalage: 0;
$navbar_underline_width: 50%;
$navbar_underline_height: 4px;
$navbar_underline_animtime: 0.25s;

// Nav icons
$navbar_iconcontainer_animtime: 0.2s;
$navbar_icon_margin: 10px;
$navbar_icon_size: 1.1rem;
$navbar_icon_border_size: 20px;
$navbar_iconcontainer_border: 1px solid $color_primary;
$navbar_iconcontainer_borderradius: 1000px;
$navbar_iconcontainer_padding: 16px;
$navbar_iconcontainer_bgcolor: transparent;
$navbar_iconcontainer_border_hover: 1px solid $color_primary;
$navbar_iconcontainer_borderradius_hover: 1000px;
$navbar_iconcontainer_bgcolor_hover: rgba($color_primary, 0.3);

// Toggler
$navbar_toggler_animation: 1; // Activer ou désactiver l'animation (0 ou 1)
$navbar_toggler_animtime: 0.2s;
$navbar-toggler-padding-y: 0.5rem;
$navbar-toggler-padding-x: 0.5rem;
$navbar_togglericon_size: 16px; // Taille de l'icone des 3 barres
$navbar_togglericon_width: 30px; // Valeurs : none si on veut un carré, taille si on veut spécifier une largeur
$navbar_toggler_borderradius: 0px;
$navbar_toggler_border: 0px solid transparent;
$navbar_toggler_bgcolor: #ffffff;
$navbar_toggler_position: right; // Valeurs : left, right
$navbar_toggler_distance: 0px;
$navbar_togglerbar_height: 4px;
$navbar_togglerbar_color: #505080;

// Comportement en mode collapsed
$navbar_collapse_nobar: 1; // Afficher la version collaspe sans barre de nav, avec juste le toggle (0 ou 1)
$navbar_collapse_floating_fixed: 1;
$navbar_collapse_floating_width: 100px;
$navbar_collapse_floating_distT: 4px;
$navbar_collapse_floating_distL: 8px;
$navbar_collapse_direction: vertical; // attention, changer dans _settings.php !
$navbar_collapse_position: top; // Valeurs : top, bottom, left, right
$navbar_collapse_height: 100vh;
$navbar_collapse_width: 100%;
$navbar_collapse_bgcolor: $color_primary; // Valeurs : couleur, keep (garder la couleur non collapse)
$navbar_collapse_content_vertical_align: center; // Valeurs : top, center, bottom
$navbar_collapse_content_horizontal_align: center; // Valeurs : left, center, right
$navbar_collapse_col_padding: 5vw;
$navbar_collapse_etage_padding: 0;
$navbar_collapse_keep_underline: 0; // 0 ou 1
$navbar_collapse_links_color: $color_white; // Valeurs : couleur, keep (garder la couleur non collapse)
$navbar_collapse_links_fontsize: 1.4rem;
$navbar_collapse_links_fontweight: 300;
$navbar_collapse_links_centering: left; // Valeurs : left, right, center
$navbar_collapse_opened_dropdown: 1; // 0 ou 1
$navbar_collapse_dropdown_decalage: 30px;
$navbar_background_nobar_color: $color_white;
$navbar_background_nobar_height: 0px;

// Variables boostrap à réinitialiser
$navbar-brand-padding-y: 0;


// ---------------------------------
// ||        Cookie notice        ||
// ---------------------------------

$cookie_notice_type: fixed; // Valeurs : fixed, page_top
$cookie_notice_position: bottom; // Valeurs : top, bottom
$cookie_notice_animation: unfold; // Valeurs : slide, unfold, opacity
$cookie_notice_animation_time: 0.3s;
$cookie_notice_padding: 10px 20px;
$cookie_notice_txtcolor: $color_white;
$cookie_notice_bgcolor: $color_primary;
$cookie_notice_button_txtcolor: $color_white;
$cookie_notice_button_bgcolor: $color_secondary;
$cookie_notice_button_bgcolor_hover: lighten($color_secondary, 5%);
$cookie_notice_link_decoration: underline;



// ---------------------------------
// ||            Forms            ||
// ---------------------------------

// Labels
$form_label_fontcolor: $color_black;
$form_label_fontsize: 1rem;
$form_label_fontweight: 500;
$form_label_fontstyle: none;
$form_label_marginbottom: 0px;
$form_label_paddingleft: 0px;
// Radios
$custom_radio: 0;
$form_radiochecklabel_fontsize: 1rem;
$form_radiochecklabel_fontweight: 500;
$form_radiochecklabel_fontstyle: none;
$form_radiochecklabel_marginbottom: 0px;
$form_radiochecklabel_paddingleft: 1.25rem;
// Inputs
$form_input_padding: 0.375rem 0.75rem;
$form_input_border: none;
$form_input_border_green: 2px solid $color_success;
$form_input_border_red: 2px solid $color_error;
$form_input_borderradius: 0;
$form_input_fontcolor: $color_primary;
$form_input_bgcolor: #dddddd;
$form_input_focus_bgcolor: #e0e0e0;
$form_input_fontsize: 1rem;
$form_input_fontweight: 500;
$form_input_fontstyle: none;
$form_input_marginbottom: 0px;
$form_input_placeholder_color: rgba($color_primary, 0.6);
// Select
$form_select_txtcolor: $color_white;
$form_select_bgcolor: $color_primary;
// Button
$form_button_position: center;
$form_button_stylize: 1;
$form_button_width: auto;
$form_button_padding: 0.2rem 1rem;
$form_button_color: $color_white;
$form_button_bgcolor: $color_primary;
$form_button_borderradius: 0.25rem;
$form_button_fontsize: 1.1rem;
$form_button_fontweight: 700;
// Loader
$form_loader_speed: 3s;
$form_loader_size: 80px;
$form_loader_padding: 20px;
$form_loader_thickness: 10px;
$form_loader_color: $color_white;
$form_loader_bgcolor: rgba($color_black, 0.2);




// ----------------------------------
// ||            Footer            ||
// ----------------------------------

$footer_padding: 3vw;
$footer_bgcolor: $color_white;
$footer_cols_hposition: spaced; // left, center, right, spaced
$footer_cols_vposition: bottom; // top, center, bottom
$footer_cols_padding: 0 30px; // top, center, bottom
$footer_separateur_horizontal: 20px;
$footer_separateur_vertical: 30px;
$footer_logo_width: 130px;
$footer_txtcolor: $color_black;
$footer_link_color: $color_black;
$footer_link_hover_color: $color_black;
$footer_link_underline: 1; // 0 ou 1