
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
div[type="submit"],
*[type="submit"],
button, 
input {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

input[type="checkbox"] {
	display: inline-block;
	appearance: checkbox !important;
}
input[type="radio"] {
	@if $custom_radio == 1 {
		cursor: pointer;
		display: inline-block;
		height: $radio_size;
		width: $radio_size;
		border: $radio_border;
		border-radius: $radio_borderradius;
		background-color: $radio_bgcolor;
		transform: translateY($radio_decalage);
	} 
	@else {
		min-height: 13px;
		appearance: radio !important;
	}
}
input[type="radio"][checked="checked"] {
	@if $custom_radio == 1 {
		border: $radio_selected_border;
		background-color: $radio_selected_bgcolor;
	}
}



$select-color: $black;
$select-background: $color_primary;
$select-width: 300px;
$select-height: 40px; 

.select-hidden {
	display: none;
	visibility: hidden;
	padding-right: 10px;
}
.select {
	cursor: pointer;
	display: inline-block;
	position: relative;
	font-size: 16px;
	color: $select-color;
	width: $select-width;
	height: $select-height;
}
.select-styled {
	position: absolute; 
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $select-background;
	padding: 8px 15px;
	border: 1px solid darken($color_primary, 15%);
	@include transition(all 0.2s ease-in);
	&:after {
		content:"";
		width: 0;
		height: 0;
		border: 7px solid transparent;
		border-color: $select-color transparent transparent transparent;
		position: absolute;
		top: 16px;
		right: 10px;
	}
	&:hover {
		background-color: darken($select-background, 4);
	}
	&:active, &.active {
		background-color: darken($select-background, 5);
		&:after {
			top: 9px;
			border-color: transparent transparent $select-color transparent;
		}
	}
}

.select-options {
	display: none; 
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 999;
	margin: 0;
	padding: 0;
	list-style: none;
	background-color: darken($select-background, 5);
	li {
		margin: 0;
		padding: 12px 0;
		text-indent: 15px;
		border-top: 1px solid darken($select-background, 10);
		@include transition(all 0.15s ease-in);
		&:hover {
			color: $select-background;
			background: $select-color;
		}
		&[rel="hide"] {
			display: none;
		}
	}
}