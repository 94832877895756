
.borlabs_generator_page {
    .copied {
        pointer-events: none;
        position: fixed;
        top: 10px;
        left: 10px;
        background-color: black;
        color: white;
        padding: 4px 16px;
        border-radius: 4px;
        opacity: 0;
        transition: 1s;

        &.visible {
            opacity: 1;
            transition: 0s;
        }
    }

    .page_title {
        margin-top: 0;

        strong {
            font-weight: 900;
        }
    }

    .infos  {
        margin-bottom: 40px;
    }

    .tools_container {
        max-width: 1000px;
        margin: auto;
        margin-top: 40px;
    }
    
    .generator_controls {
        //display: flex;
        padding: 30px;
        background-color: #ccc;
        margin-bottom: 20px;
        border-radius: 10px;

        .cols {
            display: flex;
            justify-content: space-between;

            > * {
                margin-right: 20px;
            }
        }

        .form-group {
            margin-bottom: 10px;
        }
    
        h3 {
            margin-top: 0;
        }

        label {
            display: block;
            font-size: 0.8rem;
        }

        input {
            border: 1px solid black;
            padding: 4px 8px;
        
            &::placeholder {
                color: #bbb;
            }

            &:active, &:focus {
                border: 1px solid black;
            }
        }
    
        .btn_container {
            button {
                border: none;
                width: 100%;
                padding: 15px;
                color: white;
                background-color: black;
            }
        }
    }
    
    .generated_elements {
        padding: 30px;
        background-color: #ccc;
        border-radius: 10px;
    
        textarea {
            width: 100%;
            min-height: 200px;
        }
    }
}
