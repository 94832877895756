.fixswitch_container {
	position: relative;

	.fixswitch_element {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		right: 0;
		height: 100vh;
	}

	&.fixed {
		.fixswitch_element {
			position: fixed;
			top: 0;
			bottom: 0;
		}
	}

	&.absolute_top {
		.fixswitch_element {
			top: 0;
			bottom: auto;
		}
	}

	&.absolute_bottom {
		.fixswitch_element {
			top: auto;
			bottom: 0;
		}
	}
}