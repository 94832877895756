
// ----------------------------------
// ||            Global            ||
// ----------------------------------

html { 
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0; padding: 0;
	width: 100%;
}

body {
	background-color: $main_bg_color;
	margin: 0; padding: 0;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	width: 100%;
	padding-right: 0 !important; // Pour contrer les 17px des modales boostrap
	font-family: 'nom_police_principale', sans-serif; // BASE font
	letter-spacing: 0.01rem; // BASE spacing
	color: $color_black; // BASE text color
}

section {
	position: relative;
	width: 100%;
}

section a {
	color: $links_color;
	font-weight: $links_weight;
	text-decoration: $links_decoration;
}
section a:visited {
	color: darken($links_color, 15%);
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
div[type="submit"],
*[type="submit"],
button, 
input {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

input[type="checkbox"] {
	display: inline-block;
	appearance: checkbox !important;
}
input[type="radio"] {
	display: inline-block;
	min-height: 13px;
	width: auto !important;
	appearance: radio !important;
}

button, .btn, .close {
	cursor: pointer;
}

/*select {
	background-image: url("/images/select_arrow.png");
	background-position: right center;
	background-repeat: no-repeat;
}*/

*, *:focus, *:active {
	outline: none !important;
}

img {
	//width: 100%;
	height: auto;
}



.full_site {
	position: relative;
	padding: $fullsite_padding;

	.site_content {
		min-height: 80vh;
	}
}

.hidden {
	display: none;
}

.admin_link {
	position: absolute;
	z-index: 10000;
	background-color: transparent;
	border-top: 26px solid #343a40;
	border-left: 26px solid #343a40;
	border-right: 26px solid transparent;
	border-bottom: 26px solid transparent;
	top: -36px;
	left: -36px;
	animation-name: admin_link_start;
    animation-duration: 0.8s;
	transition: 0.3s;

	i {
		color: white;
		position: absolute;
		top: -20px;
		left: -20px;
	}
}
.admin_link:hover {
	top: 0px;
	left: 0px;
}
@keyframes admin_link_start {
    from {top: 0px;left: 0px;}
    to {top: -36px;left: -36px;}
}

.grecaptcha-badge { display: none; }

.scrollnav {
	position: fixed;
	z-index: 100;
	right: 0;
	bottom: 10%;
	/* transform: translateY(-50%); */
	width: 50px;
	/* background-color: rgba($color_white, 0.2); */

	.scrollnav_link {
		height: 10px;
		width: 10px;
		background-color: rgba($color_black, 0.3);
		margin: 20px 50%;
		border-radius: 1000px;
		margin-left: 50%;
		transform: translateX(-50%);
	}
	.scrollnav_link:hover {
		background-color: rgba($color_black, 0.5);
	}
	.scrollnav_link.active {
		background-color: rgba($color_primary, 1);
	}
}

.popover_content_container { display: none; }
.popover {
	max-width: 350px;

	.popover-header {
		white-space: nowrap;
		color: #c0c0c0;

		.title {
			display: inline-block;
			font-weight: 700;
		}
	}

	.close_pop {
		cursor: pointer;
		display: inline-block;
		margin-left: 10px;
	}
}

.bg_image {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.altfont {
	font-family: 'nom_police_alternative', sans-serif;
	letter-spacing: 0.05rem;
}

.txt_blanc { color: $color_white !important; }
.txt_noir { color: $color_black !important; }

.container.small { max-width: 800px; }
.container.verysmall { max-width: 500px; }


.square_container:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.square_container {
	display: block;
	position: relative;
	
	.square_content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		img {
			@extend %imgcover;
		}
	}
}

.bloc_169e {
	position: relative;
	padding-bottom: 56.25%;

	> * {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
	}
}

// ----------------- CSS SPECIFIQUE AU SITE -----------------

/*.bouton_type_1 {
	cursor: pointer;
	padding-bottom: 4px;
	font-size: 1.3rem;
	line-height: 1rem;
	font-weight: 700;
	color: $color_black !important;
	border-bottom: 1px solid $color_black;
	text-decoration: none;
}
.bouton_type_1:hover {
	text-decoration: none;
	border-bottom: 2px solid $color_black;
}
.bouton_type_2 {
	cursor: pointer;
	background-color: rgba($color_bleu, 0);
	border: 1px solid $color_bleu;
	border-radius: 0;
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 700;
}
.bouton_type_2:hover {
	text-decoration: none;
	border: 1px solid $color_bleu;
	background-color: rgba($color_bleu, 0.1);
}*/



@include media-breakpoint-down($responsive_breakpoint) {
	
}

// ----------------------------------------------------------


// VARIABLE FONTS
@font-face {
	font-family: Archivo;
	src: url('../fonts/archivo-variablefont.ttf') format('truetype-variations');
	//font-weight: 1 999;
}

@font-face {
	font-family: Fraunces;
	src: url('../fonts/fraunces-variablefont.ttf') format('truetype-variations');
	//font-weight: 1 999;
}

@font-face {
	font-family: Cabin;
	src: url('../fonts/cabin-variablefont.ttf') format('truetype-variations');
	//font-weight: 1 999;
}

@font-face {
	font-family: Georama;
	src: url('../fonts/georama-variablefont.ttf') format('truetype-variations');
	//font-weight: 1 999;
}

@font-face {
	font-family: Alumni Sans;
	src: url('../fonts/alumnisans-variablefont.ttf') format('truetype-variations');
	//font-weight: 1 999;
}

@font-face {
	font-family: Literata;
	src: url('../fonts/literata-variablefont.ttf') format('truetype-variations');
	//font-weight: 1 999;
}

.variable_fonts {
	background-color: #222;

	* {
		z-index: 10;
		cursor: initial;
		user-select: none;
		color: white;
	}

	.fonts_container {
		position: relative;
		width: 500px;
		height: 600px;
		margin: auto;
	}

	.titre1 {
		position: absolute;
		top: 100px;
		right: 120px;
		//transform: translateX(-50%);
		font-family: Literata;
		font-size: 6rem;
		font-variation-settings: 'wght' 100.00;
		letter-spacing: 0rem;
		transition: 0.4s;

		&:hover {
			letter-spacing: 0.2rem;
			font-variation-settings: 'wght' 900.00;
		}
	}

	.titre2 {
		position: absolute;
		top: 120px;
		right: -40px;
		transform: rotate(90deg);
		font-family: 'Georama', sans-serif;
		font-size: 9rem;
		font-variation-settings: 'wght' 10.00, 'wdth' 150;
		transition: 0.4s;

		&:hover {
			font-variation-settings: 'wght' 900.00, 'wdth' 120;
			right: -30px;
		}
	}

	.titre3 {
		position: absolute;
		top: 200px;
		left: 0;
		//transform: translateX(-50%);
		font-family: 'Fraunces', sans-serif;
		font-size: 6rem;
		font-variation-settings: 'wght' 900.00, 'SOFT' 100;
		letter-spacing: 0rem;
		transition: 0.4s;

		&:hover {
			letter-spacing: 0.4rem;
			font-variation-settings: 'wght' 700.00, 'SOFT' 0;
		}
	}

	.titre4 {
		position: absolute;
		top: 470px;
		left: 30px;
		transform-origin: left center;
		transform: rotate(-90deg);
		font-family: 'Georama', sans-serif;
		font-size: 5rem;
		font-variation-settings: 'wght' 10.00, 'wdth' 100;
		transition: 0.4s;

		&:hover {
			font-variation-settings: 'wght' 900.00, 'wdth' 95;
			//right: -20px;
		}
	}

	.titre5 {
		position: absolute;
		top: 290px;
		left: 70px;
		//transform-origin: left center;
		//transform: rotate(-90deg);
		font-family: 'Literata', sans-serif;
		font-size: 5rem;
		font-variation-settings: 'wght' 900.00, 'wdth' 100;
		transition: 0.4s;

		&:hover {
			font-variation-settings: 'wght' 200.00, 'wdth' 95;
			//right: -20px;
		}
	}
}

.fonts {
	font-family: 'Cabin', sans-serif;
	font-family: 'Fraunces', serif;
	font-family: 'Georama', sans-serif;
}