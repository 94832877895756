
// STYLES GENERAUX

h1, h2, h3, h4, h5, h6 {
    font-family: arial narrow;
}

.lien_trait_ondule {
    display: inline-block;
    text-decoration: none;
    transition: 0.1s;

    .line {
        display: flex;
        align-items: center;
    }

    #svg_trait_ondule {
        background-color: transparent;

        #path_trait_ondule { 
            fill: transparent;
            stroke: #000;
            stroke-width: 3px;
            stroke-linecap: round;
            transition: 0.15s;
        }
    }

    .label {
        margin-left: 5px;
        line-height: 1;
        font-size: 1.1rem;
        text-transform: uppercase;
        white-space: nowrap;

        .char {
            transform: translateY(0px);
            transition: transform 0.1s ease-out;
        }
    }

    &:hover {
        text-decoration: none;
        color: #ffc533;

        .label {
            .char {
                animation: 0.7s ease-out 0s 1 forwards;
                animation-name: anim_char;
                animation-delay: calc(0.03s * var(--char-index));
            }
        }

        @keyframes anim_char {
            0% { transform: translateY(0px); }
            15% { transform: translateY(-10px); }
            30% { transform: translateY(8px); }
            45% { transform: translateY(-6px); }
            60% { transform: translateY(4px); }
            85% { transform: translateY(-2px); }
            100% { transform: translateY(0px); }
        }

        // .label {
        //     .char[style*="index:0;"] { transform: translateY(-10px); }
        //     .char[style*="index:1;"] { transform: translateY(-8px); }
        //     .char[style*="index:2;"] { transform: translateY(-4px); }
        //     .char[style*="index:3;"] { transform: translateY(4px); }
        //     .char[style*="index:4;"] { transform: translateY(8px); }
        //     .char[style*="index:5;"] { transform: translateY(10px); }
        //     .char[style*="index:6;"] { transform: translateY(8px); }
        //     .char[style*="index:7;"] { transform: translateY(4px); }
        //     .char[style*="index:8;"] { transform: translateY(-4px); }
        //     .char[style*="index:9;"] { transform: translateY(-8px); }
        //     .char[style*="index:10;"] { transform: translateY(-10px); }
        //     .char[style*="index:11;"] { transform: translateY(-8px); }
        //     .char[style*="index:12;"] { transform: translateY(-4px); }
        //     .char[style*="index:13;"] { transform: translateY(4px); }
        //     .char[style*="index:14;"] { transform: translateY(8px); }
        //     .char[style*="index:15;"] { transform: translateY(10px); }
        //     .char[style*="index:16;"] { transform: translateY(8px); }
        //     .char[style*="index:17;"] { transform: translateY(4px); }
        //     .char[style*="index:18;"] { transform: translateY(-4px); }
        //     .char[style*="index:19;"] { transform: translateY(-8px); }
        //     .char[style*="index:20;"] { transform: translateY(-10px); }
        //     .char[style*="index:21;"] { transform: translateY(-8px); }
        //     .char[style*="index:22;"] { transform: translateY(-4px); }
        //     .char[style*="index:23;"] { transform: translateY(4px); }
        //     .char[style*="index:24;"] { transform: translateY(8px); }
        //     .char[style*="index:25;"] { transform: translateY(10px); }
        //     .char[style*="index:26;"] { transform: translateY(8px); }
        //     .char[style*="index:27;"] { transform: translateY(4px); }
        //     .char[style*="index:28;"] { transform: translateY(-4px); }
        //     .char[style*="index:29;"] { transform: translateY(-8px); }
        //     .char[style*="index:30;"] { transform: translateY(-10px); }
        //     .char[style*="index:31;"] { transform: translateY(-8px); }
        //     .char[style*="index:32;"] { transform: translateY(-4px); }
        //     .char[style*="index:33;"] { transform: translateY(4px); }
        //     .char[style*="index:34;"] { transform: translateY(8px); }
        //     .char[style*="index:35;"] { transform: translateY(10px); }
        //     .char[style*="index:36;"] { transform: translateY(8px); }
        //     .char[style*="index:37;"] { transform: translateY(4px); }
        //     .char[style*="index:38;"] { transform: translateY(-4px); }
        //     .char[style*="index:39;"] { transform: translateY(-8px); }
        //     .char[style*="index:40;"] { transform: translateY(-10px); }
        // }

        #svg_trait_ondule {
            #path_trait_ondule { 
                stroke: #ffc533;
            }
        }
    }
}

// STYLES SECTIONS

section.entete {
    height: 100vw;
    max-height: 80vh;

    .logo {
        @extend %abcenter_xy;
        height: 80%;
        max-width: 90vw;
    }

    .fond {
        @extend %abcenter_xy;
        @extend %imgcover;
        z-index: -1;
    }
}

section.intro {
    margin-bottom: 80px;
    
    .illu {
        @extend %imgcover;
    }

    .right {
        padding: 4vw 7vw;
        @include media-breakpoint-down(lg) {
            padding: 30px 40px;
        }
    }

    .titre {
        font-size: calc(2rem + 3vw);
        font-weight: 700;
        line-height: 1;
        margin: 0;
    }

    .soustitre {
        font-size: calc(0.7rem + 1.3vw);
        font-weight: 700;
        margin-bottom: 30px;
    }

    p {
        line-height: 1.2;
    }

    .pictos_ab_ecocert {
        height: 60px;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 100px;
        .right { order: 1; }
        .left { order: 2; }
    }
}

// BLOCS PRODUITS

.product_bloc {
    padding: calc(20px + 4vw) 0;

    // Général
    .title_side {
        padding: 7vw;

        @include media-breakpoint-down(lg) {
            margin-top: 30px;
        }
    }

    h2 {
        font-size: calc(2rem + 3vw);
        font-weight: 700;
    }

    .bouteille {
        position: absolute;
        z-index: 1;
        height: calc(100% + 10vw);
        top: 50%;
        transform: translateY(-50%);
    }

    .parallax_frame {
        position: relative;
        overflow: hidden;
        height: 100%;
        min-height: 340px;

        img {
            position: absolute;
        }
    }

    // Gestion alternance gauche/droite
    &:nth-child(odd) {
        .bouteille {
            left: 12%;
        }

        @include media-breakpoint-up(lg) {
            .title_side { order: 1; }
            .image_side { order: 2; }
        }
    }
    &:nth-child(even) {
        .bouteille {
            right: 12%;
        }
    }

    // Specificités de chaque bloc
    &:nth-child(1) { 
        .parallax_frame { background-color: #ffc533; } 
        .lien_trait_ondule:hover { color: #ffc533; }
        .lien_trait_ondule:hover #svg_trait_ondule { #path_trait_ondule { stroke: #ffc533; } }
    }
    &:nth-child(2) { 
        .parallax_frame { background-color: #945824; } 
        .lien_trait_ondule:hover { color: #945824; }
        .lien_trait_ondule:hover #svg_trait_ondule { #path_trait_ondule { stroke: #945824; } }
    }
    &:nth-child(3) { 
        .parallax_frame { background-color: #afce4d; } 
        .lien_trait_ondule:hover { color: #afce4d; }
        .lien_trait_ondule:hover #svg_trait_ondule { #path_trait_ondule { stroke: #afce4d; } }
    }
    &:nth-child(4) { 
        .parallax_frame { background-color: #ffe69b; } 
        .lien_trait_ondule:hover { color: #ffe69b; }
        .lien_trait_ondule:hover #svg_trait_ondule { #path_trait_ondule { stroke: #ffe69b; } }
    }
    
    &.banane_ananas {
        .banane {
            width: 80%;
            top: -40%;
            right: 5%;
        }

        .ananas {
            width: 45%;
            right: 15%;
            bottom: 15%;
        }
    }

    &.kiwi_cacao {
        .kiwi1 {
            width: 30%;
            left: 35%;
            top: -25%;
        }

        .kiwi2 {
            width: 40%;
            left: 7%;
            bottom: -20%;
        }

        .grains_cafe1 {
            width: 70%;
            right: 15%;
        }

        .grains_cafe2 {
            width: 70%;
            right: 15%;
        }
    }
}